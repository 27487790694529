<template>
  <div class="sb" @click="toggleSearchBox">
    <!-- <div class="bank_logo">
      <i class="icon-plus icplus"/>
    </div> -->

    <img loading="lazy" class="img" v-if="RCategoryImages[category]" :src="RCategoryImages[category]" alt=""/>

    <div class="names">
      <!-- <div class="name"  v-if="category === ECategoryType.Lamp">
        {{ $t('create_diary_lamp_hint_type') }}
        /
        {{ $t('create_diary_lamp_hint_power') }}
      </div> -->

      <div class="name" v-if="category === ECategoryType.Seed">
        + {{ $t('create_diary_view_strains_button_add') }}
      </div>

      <div class="name" v-else>
        + {{ $t(`create_diary_${category}_hint`) }}
      </div>

      <!-- <div class="sec_name">
        {{ $t('select') }}
      </div> -->
    </div>

  </div>


  <GeneralSelectBoxModal
      v-if="showed"
      :cat="category"
      :input-placeholder="'Search...'"
      :input-placeholder-old="$t(`create_diary_add_brand_${category}_placeholder`)"
      :title-modal="$t(`create_diary_add_brand_${category}_placeholder`)"
      :showed="showed"
      :faza="faza"
      @choose="chooseItem"
      @prechoose="prechooseItem"
      @close="showed = false"
      @customize="showedCustom = true"
    />

  <GeneralSelectBoxModalCustom
      v-if="showedCustom"
      :cat="category"
      :input-placeholder="'Custom name'"
      :title-modal="$t(`create_diary_add_brand_${category}_placeholder`)"
      :showed="showedCustom"
      :faza="faza"
      :prechoosed="prechoosed"
      @choose="chooseItem"
      @close="showedCustom = false"
    />

</template>

<script setup lang="ts">

import { ECategoryType, RCategoryDescriptions, RCategoryImages, ELampFaza } from "~/types/Enums"

const props = defineProps<{ category: ECategoryType, faza?: ELampFaza }>()
const emits = defineEmits(['choose'])
const showed = ref(false)
const showedCustom = ref(false)
const prechoosed = ref(null)


const chooseItem = (data: any) => {
  emits('choose', data)
  showed.value = false
  showedCustom.value = false
}

const prechooseItem = (data: any) => {
  prechoosed.value = data
}
const toggleSearchBox = () => {
  showed.value = !showed.value;
}

const showCustom = () => {
  showed.value = false;
  showedCustom.value = true;
}
</script>



<style scoped>
.sb {
  align-items: center;
  background-color: var(--un-background-color-gray);
  /* border-bottom: 2px solid var(--un-primary-back-color); */
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 190px;
  padding: 1rem;
  position: relative;
  width: 170px;
}

.sb:not(.created) {
  cursor: pointer;
}

.sb img {
  width: 80px;
  margin: auto;
}
.bank_logo {
  width: 80%;
  padding: 5px;
  margin: auto;
}

.bank_logo .lg {
  width: 100%;
}

.bank_logo .icplus {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.names {
  width: 100%;
}

.names .name {
  font-weight: bold;
  text-align: center;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  display: block;
  width: 100%;
  color: var(--un-text-color-firm);
}

.names .sec_name {
  color: gray;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.action {
  position: absolute;
  right: 5px;
  top: -5px;
  width: 24px;
}

.types {
  background-color: var(--un-primary-back-color);
  border-radius: 0 0 19px 19px;
  border-top: 1px white solid;
  box-shadow: inset 0 4px 10px #0000000f;
  display: flex;
  margin-left: 0;
  margin-top: .5rem;
  width: calc(100% + 2rem);
}

.types .type {
  color: #ffffff94;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  width: 50%;
}

.types .type:first-child {
  border-radius: 0 0 0 19px;
  padding: 10px 5px 10px 15px;
}

.types .type:last-child {
  border-radius: 0 0 19px 0;
  padding: 10px 15px 10px 5px;
}

.types .type:hover,
.types .type.active {
  color: #fff;
}

.cnt {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: .5rem;
}

.cnt .mn {
  align-items: flex-start;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: bold;
  height: 24px;
  justify-content: center;
  line-height: 22px;
  width: 24px;
}

.cnt .pl {
  align-items: flex-start;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 700;
  height: 24px;
  justify-content: center;
  line-height: 22px;
  width: 24px;
}

.cnt .cn {
  margin: 0 0.5rem;
  font-weight: bold;
}

.action .btn_trash {
  align-items: center;
  background-color: #db2828 !important;
  border-color: #db2828 !important;
  border-radius: 30px;
  color: #fff !important;
  display: flex;
  height: 25px;
  justify-content: center;
  position: absolute;
  right: -10px;
  width: 26px;
}

.edit_diary .new_strain .types {
  pointer-events: none;
}

@container pb (max-width: 600px) {
  .sb {
    border-radius: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 0;
    min-height: auto;
    width: 100%;
  }

  .sb .bank_logo {
    height: 40px;
    margin-right: 10px;
    overflow: hidden;
    width: 65px;
  }

  .sb .img{
    width: 65px;
    height: 40px;
    aspect-ratio: none;
    margin: 0;
    margin-right: 10px;
  }

  .sb .names {
    text-align: left;
    justify-content: left;
    margin-right: auto;
    width: calc(100% - 150px);
  }

  .sb .action {
    position: absolute;
    right: 0;
    top: 0;
  }

  .edit_diary .new_strain .sb .bank_logo {
    height: 40px;
    overflow: hidden;
  }

  .edit_diary .new_strain .sb .bank_logo img {
    margin-left: -14px;
    margin-top: -15px;
    width: 59px;
  }

  .sb .names .name {
    text-align: left;
  }

  .sb .names .sec_name {
    text-align: left;
  }

  .edit_diary .new_strain {
    width: 100%;
  }

  .sb .cnt {
    width: 80px;
    margin-top: 0;
  }

  .sb .types {
    border-radius: 0 0 10px 10px;
    margin-left: -1rem;
    margin-right: -1rem;
    width: calc(100% + 2rem);
  }

  .sb .types .type:first-child {
    border-radius: 0 0 0 19px;
    padding: 5px 5px 5px 15px;
  }

  .sb .types .type:last-child {
    border-radius: 0 0 19px 0;
    padding: 5px 15px 5px 5px;
  }
}
</style>