<template>
  <div class="sb" v-if="item" :class="styleClass">
    <div class="bank_logo">
      <template v-if="category === ECategoryType.Medium">
        <img loading="lazy" :src="'/images/medium/' + (item?.id || 7) + '.svg'" class="lg" alt="">
      </template>
      <template v-else>
        <!-- <img loading="lazy" v-if="!item.item_brand?.logo_s " src="/images/setup_diary/setup_diary_icons-18.svg" alt=""> -->
        <img :src="avatar" data-not-lazy alt="">
      </template>
    </div>

    <div class="action">
      <div @click="clone" class="btn_clone" v-if="category === ECategoryType.Lamp">
        <i class="icon-clone"/>
      </div>

      <div @click="remove" class="btn_trash">
        <i class="icon-remove"/>
      </div>
    </div>


    <div class="names" v-if="category !== ECategoryType.Medium">
      

      <!-- <div class="name add-custom" v-if="!item.name" @click="showed = true">
        + {{ $t(`create_diary_add_${category}_placeholder`) }}
      </div> -->


      <div class="name" v-if="category == ECategoryType.Lamp && (item.name || item.props.type || item.props.power)">
        {{ item.name || '' }} 
        {{ item.props.type ?  $t($constants.typeLightShort[item.props.type]) : '' }} 
        {{ item.props.power && item.props.power ? '/' : '' }}
        {{ item.props.power ? item.props.power + 'W' : '' }}        
      </div>

      <div class="name" v-else-if="item.name">
        {{ item.name }}
      </div>

      <div class="sec_name">
          {{ item.item_brand.name ?? $t("diary_info_custom_brand_box_name") }}
      </div>




      <template v-if="category === ECategoryType.Seed">
        <div class="cnt">
          <div class="mn" @click="setCounter(-1)">-</div>

          <div class="cn">{{ item?.props?.cnt }}</div>

          <div class="pl" @click="setCounter(1)">+</div>
        </div> 
      </template>


      <div v-if="!item.name" class="act-add" @click="item.item_brand?.id ? showed = true : showedCustom = true">    
         + Add Name
      </div>
      
    </div>




    <template v-if="category === ECategoryType.Medium">
      <div class="names">
        <div class="sec_name">{{ item.name }}</div>
      </div>

      <div class="cnt">
        <div class="mn" @click="counterMedium(-1)">-</div>

        <div class="cn">{{ item.value }}%</div>

        <div class="pl" @click="counterMedium(1)">+</div>
      </div>

      <div class="sld">
        <input type="range" class="range" max="100" min="0" @change="changeMedium" v-model="item.value">
      </div>
    </template>

    <template v-if="category === ECategoryType.Seed && false">
      
      <div class="types">
        <div @click="changeSeedType(1)" :class="'type' + (Number(item?.props?.type) === 1 ? ' active ' : '')">
          {{ $t("universal_type_seed_seeds") }}
        </div>

        <div @click="changeSeedType(2)" :class="'type' + (Number(item?.props?.type) === 2 ? ' active ' : '')">
          {{ $t("universal_type_seed_clones") }}
        </div>
      </div>
    </template>

  </div>

  <GeneralSelectBoxModal
    v-if="showed"
    :cat="category"
    :input-placeholder="'Search...'"
    :title-modal="$t(`create_diary_add_${category}_placeholder`)"
    :showed="showed"
    :ref-id="item?.ref_id"
    :brand-id="item?.item_brand?.id"
    :brand-name="item?.item_brand?.name"
    :brand-avatar="item?.item_brand?.logo_s"
    :faza="item?.props?.faza"
    :type="'product'"
    @choose="chooseItem"
    @prechoose="prechooseItem"
    @close="showed = false"
    @customize="showedCustom = true"
  />

  <GeneralSelectBoxModalCustom
      v-if="showedCustom"
      :cat="category"
      :type="'product'"
      :input-placeholder="'Custom Product Name'"
      :title-modal="$t(`create_diary_add_${category}_placeholder`)"
      :showed="showedCustom"
      :ref-id="item?.ref_id"
      :brand-id="item?.item_brand?.id"
      :brand-name="item?.item_brand?.name"
      :brand-avatar="item?.item_brand?.logo_s"
      :faza="item.props?.faza"
      :prechoosed="prechoosed"
      @choose="chooseItem"
      @close="showedCustom = false"
    />

</template>

<script setup lang="ts">


import { ECategoryType } from "~/types/Enums"


const props = defineProps({
  item: {
    type: Object
  },
  category: {
    type: String,
    required: true
  },
  totalMedium: {
    type: Number,
    required: false,
    default: 0
  },
  cnt: {
    type: Object,
    required: false,
    default: null
  }  
})

const showed = ref(false)
const showedCustom = ref(false)
const prechoosed = ref(null)

const emits = defineEmits(['remove', 'clone', 'choose'])

const remove = () => {
  emits('remove')
}

const clone = () => {
  emits('clone', props.item)
}

const styleClass = computed(() => {
  return props.category ? 'sb_' + props.category : ''
})

const changeSeedType = (vl: any) => {
  props.item.props.type = vl
}

const setCounter = (vl: number) => {
  if (Number(props.item.props.cnt) + vl > 0) props.item.props.cnt = Number(props.item.props.cnt) + vl
}

const changeMedium = () => {
  let result = parseInt(props.item.value)

  if (props.totalMedium as number > 100) {
    props.item.value = 100 - (props.totalMedium as number - result)
  }
}

const counterMedium = (vl: number) => {
  let result = parseInt(props.item.value)

  if (props.totalMedium as number >= 100 && vl > 0) return

  if (result + vl > 0) {
    props.item.value = result + vl
  }

  changeMedium()
}

const avatar = computed(() => {
  if(props.category == 'seed') return props.item?.item_brand?.logo_s;
  return getEquipAvatar(props.item?.item_brand?.name, props.category, props.item?.props?.type)
})



const chooseItem = (data: any) => {
  console.log('data', data);
  emits('choose', data)
  showed.value = false
  showedCustom.value = false
}

const prechooseItem = (data: any) => {
  prechoosed.value = data
}
const toggleSearchBox = () => {
  showed.value = !showed.value;
  showedCustom.value = !showedCustom.value;
}

onMounted(() => {
  if (!props.item.showProduct)
    return  
  if (props.item.item_brand?.id) {
    showed.value = true
  }else{
    showedCustom.value = true
  }
})

</script>
<style scoped>
  .sb {
    align-items: center;
    background-color: var(--un-background-color-gray);
    /* border-bottom: 2px solid var(--un-primary-back-color); */
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 190px;
    padding: 1rem;
    position: relative;
    width: 170px;
    user-select: none;
  }

  .sb .act-add {
    margin-top: 5px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: var(--un-text-color-firm);
    cursor: pointer;
  }

  .sb .action {
    position: absolute;
    right: 5px;
    top: -5px;
    width: 24px;
  }

  .sb .action .btn_clone {
    align-items: center;
    background-color: #cecece !important;
    border-radius: 30px;
    color: #666 !important;
    cursor: pointer;
    display: flex;
    height: 25px;
    justify-content: center;
    position: absolute;
    right: 20px;
    width: 26px;
  }

  .sb .action .btn_trash {
    align-items: center;
    background-color: #db2828 !important;
    border-color: #db2828 !important;
    border-radius: 30px;
    color: #fff !important;
    cursor: pointer;
    display: flex;
    height: 25px;
    justify-content: center;
    position: absolute;
    right: -10px;
    width: 26px;
    line-height: 0.7rem;
  }

  .sb .bank_logo {
    align-items: center;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;

    width: 60%;
    margin-bottom: 10px;
  }

  .sb .bank_logo img {
    aspect-ratio: 1/1;
    object-fit: contain;
    width: 100%;
  }

  .sb .names {
    width: 100%;
  }

  .sb .names .name {
    display: block;
    font-weight: 700;
    min-height: 1.4em;
    text-align: center;
    width: 100%;
  }

  .sb .names .name.add-custom {
    color: var(--un-text-color-firm);
  }

  .sb .names .sec_name {
    color: gray;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
 


  @container pb (max-width: 600px) {
    .sb {
      min-height: auto;
      width: 100%;
      flex-direction: row;
      margin-right: 0;
      border-radius: 10px;
      background-color: var(--un-background-color-gray);
      flex-wrap: wrap;
    }

    .sb .bank_logo {
      height: 40px;
      margin-right: 10px;
      overflow: hidden;
      width: 65px;
      margin-bottom: 0;
    }

    .sb .bank_logo img {
      width: 100%;
      height: 100%;
    }

    .sb .act-add{
      text-align: left;
    }

    .sb .cnt {
      width: 80px;
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 10px;
    }

    .sb .names {
      text-align: left;
      justify-content: left;
      margin-right: auto;
      width: calc(100% - 80px);
    }

    .sb .names .name {
      text-align: left;
    }

    .sb .names .sec_name {
      text-align: left;
    }

    .sb .types .type:first-child {
      align-items: center;
      border-radius: 0 0 0 19px;
      display: flex;
      justify-content: center;
      padding: 5px 5px 5px 15px;
    }

    .sb .types .type:last-child {
      align-items: center;
      border-radius: 0 0 19px;
      display: flex;
      justify-content: center;
      padding: 5px 15px 5px 5px;
    }
  }


  /* SEED */


  .sb.sb_seed {
    justify-content: center;
    max-width: 100%;
    min-height: 220px;
  }

  .sb.sb_seed:not(.created) {
    cursor: pointer;
  }

  .sb.sb_seed .bank_logo {
    width: 80%;
    padding: 5px;
    aspect-ratio: 1/1;
  }

  .sb.sb_seed .bank_logo .lg {
    width: 100%;
  }

  .sb.sb_seed .bank_logo .icplus {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
  }

  .sb.sb_seed .cnt {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
    margin-bottom: 5px;
  }


  .sb.sb_seed .cnt .pl,
  .sb.sb_seed .cnt .mn {
    align-items: flex-start;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    font-size: 15px;
    font-weight: 700;
    height: 30px;
    justify-content: center;
    line-height: 28px;
    width: 30px;
    background-color: var(--un-background-color);
    user-select: none;
  }
 
  .sb.sb_seed .cnt .cn {
    font-weight: 700;
    margin: 0 0.5rem;
  }

  .sb.sb_seed .names {
    width: 100%;
  }

  .sb.sb_seed .names .name {
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
  }

  .sb.sb_seed .names .sec_name {
    color: gray;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sb.sb_seed .types {
    background-color: var(--un-primary-back-color);
    border-radius: 0 0 19px 19px;
    border-top: 1px #fff solid;
    box-shadow: inset 0 4px 10px #0000000f;
    display: flex;
    margin: 0.5rem 0 0;
    width: calc(100% + 2rem);
  }

  .sb.sb_seed .types .type {
    color: #ffffff94;
    cursor: pointer;
    font-weight: 700;
    padding: 10px;
    text-align: center;
    width: 50%;
  }

  .sb.sb_seed .types .type:first-child {
    border-radius: 0 0 0 19px;
    padding: 10px 5px 10px 15px;
  }

  .sb.sb_seed .types .type:last-child {
    border-radius: 0 0 19px 0;
    padding: 10px 15px 10px 5px;
  }

  .sb.sb_seed .types .type:hover,
  .sb.sb_seed .types .type.active {
    color: #fff;
  }

  @container pb (max-width: 600px) {
    .sb.sb_seed {
      min-height: auto;
      width: 100%;
      flex-direction: row;
      margin-right: 0;
      border-radius: 10px;
      background-color: var(--un-background-color-gray);
      flex-wrap: wrap;
    }

    .sb.sb_seed .bank_logo {
      height: 40px;
      margin-right: 10px;
      overflow: hidden;
      width: 65px;
    }

    .sb.sb_seed .names .cnt {
      width: 80px;
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 10px;
      grid-area: cnt;
    }

    .sb.sb_seed .names {
      text-align: left;
      justify-content: left;
      margin-right: auto;
      width: calc(100% - 90px);
      display: grid;
      grid-template-areas: 
      "name cnt"
      "sec_name cnt"
      ;
      gap: 5px;
      grid-template-columns: 1fr 80px;
      

    }
 
    .sb.sb_seed .names .name {
      text-align: left;
      grid-area: name;
    }

    .sb.sb_seed .names .sec_name {
      text-align: left;
      grid-area: sec_name;
    }

    .sb.sb_seed .types .type:first-child {
      align-items: center;
      border-radius: 0 0 0 19px;
      display: flex;
      justify-content: center;
      padding: 5px 5px 5px 15px;
    }

    .sb.sb_seed .types .type:last-child {
      align-items: center;
      border-radius: 0 0 19px;
      display: flex;
      justify-content: center;
      padding: 5px 15px 5px 5px;
    }
  }




  /* MEDIUM */

  .sb.sb_medium .cnt {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
  }

  .sb.sb_medium .names{
    width: calc(100% - 180px);
  }

  .sb.sb_medium .cnt .pl ,
  .sb.sb_medium .cnt .mn {
    display: flex;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    font-size: 15px;
    font-weight: 700;
    height: 30px;
    justify-content: center;
    line-height: 28px;
    width: 30px;
    background-color: var(--un-background-color);
    user-select: none;
  } 
  .sb.sb_medium .cnt .cn {
    margin: 0 0.5rem;
    font-weight: 700;
  }

  .sb.sb_medium .sld {
    bottom: -16px;
    left: 1rem;
    position: absolute;
    width: calc(100% - 2rem);
    z-index: 3;
  }

  .sb.sb_medium .sld input[type="range"] {
    -webkit-appearance: none;
    background-color: transparent;
    height: 20px;
    width: 100%;
  }

  .sb.sb_medium .sld input[type="range"]:focus {
    outline: none;
  }

  .sb.sb_medium .sld input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: var(--un-primary-back-color);
    border-radius: 5px;
  }

  .sb.sb_medium .sld input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0 0 5px #00000042;
    border: 1px solid #cecece;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -9px;
  }

  .sb.sb_medium .sld input[type="range"]:focus::-webkit-slider-runnable-track {
    background: var(--un-primary-back-color);
  }

  .sb.sb_medium .sld input[type="range"]::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: var(--un-primary-back-color);
    border-radius: 1.3px;
    border: 0;
  }

  .sb.sb_medium .sld input[type="range"]::-moz-range-thumb {
    box-shadow: 0 0 5px #00000042;
    border: 1px solid #cecece;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -9px;
  }

  .sb.sb_medium .sld input[type="range"]::-ms-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: var(--un-primary-back-color);
    border-radius: 5px;
  }

  .sb.sb_medium .sld input[type="range"]::-ms-fill-lower {
    background: var(--un-primary-back-color);
    border-radius: 2.6px;
  }

  .sb.sb_medium .sld input[type="range"]::-ms-fill-upper {
    background: var(--un-primary-back-color);
    border-radius: 10px;
  }

  .sb.sb_medium .sld input[type="range"]::-ms-thumb {
    border: 1px solid #cecece;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #fff;
    cursor: pointer;
  }

  .sb.sb_medium .sld input[type="range"]:focus::-ms-fill-lower {
    background: var(--un-primary-back-color);
  }

  .sb.sb_medium .sld input[type="range"]:focus::-ms-fill-upper {
    background: var(--un-primary-back-color);
  }

 
 
</style>